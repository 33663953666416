<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div>
      <div class="header-img">
        <div class="title-img">
          <div style="font-size: 42px;font-weight: 500;color: rgba(0, 82, 217, 1);position: relative;">
            <div>智能投保平台</div>
            <div style="width: 380px;position: absolute;bottom: -40px;left: -60px;"><img src="./../static/zntb.png" alt=""></div>
          </div>
          <div style="font-size: 24px;font-weight: 400;color: rgba(102, 102, 102, 1);margin-top:10px;">全流程在线办理 极速出函</div>
        </div>
        <div class="title-tip w1300">
          <div style="display: flex;justify-content: space-between;">
            <div v-for="(item,index) in titleArr" :key="item.id" style="display: flex;flex-direction: column;align-items: center;">
              <div style="width: 75px;height: 52px;"><img :src="item.url" alt="" style="object-fit:none;margin-left: 5px;"></div>
              <div style="display: flex;align-items: center;padding-top:15px;">
                <div style="width: 82px;height: 0px;border: 1px dashed rgb(42, 123, 234);" :style="index>2?'border: 1px dashed rgb(166,166,166);':''"></div>
                <div style="width: 16px;height: 16px;background: rgb(42, 123, 234);border-radius:50%;" :style="index>2?'background: rgb(215, 215, 215);':''"></div>
                <div style="width: 82px;height: 0px;" :style="index<2?'border:1px dashed rgb(42, 123, 234);':'border: 1px dashed rgb(166,166,166);'"></div>
              </div>
              <div style="padding-top:15px;font-size: 18px;color: rgb(69, 87, 124);" :style="index>2?'color: rgb(215, 215, 215);':''">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="SignUpForms">
        <div class="w1300 SignUpForms-box">
          <div class="shadow">
            <div>
              <div class="img-tip">
                <div class="number-img"><img src="./../static/01-img.png" alt=""></div>
                <div class="number-tip">投保信息</div>
              </div>
              <div style="margin-top:35px;">
                <el-form label-position="right" ref="ruleFormRef" :model="localMesage" :rules="rules" label-width="120" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
                  <el-form-item label="承保机构:" prop="institutionName">
                    <el-input placeholder="请输入承保机构" v-model="localMesage.institutionName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="保费金额:" prop="premiumAmount">
                    <el-input placeholder="请输入保费金额" v-model="localMesage.premiumAmount" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="保函编号:" prop="guaranteeSn">
                    <el-input placeholder="请输入保函编号" v-model="localMesage.guaranteeSn" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="承保机构联系方式:" prop="institutionContact">
                    <el-input placeholder="请输入承保机构联系方式" v-model="localMesage.institutionContact" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="投保人类型:" prop="bidType">
                    <el-input placeholder="请输入投保人类型" v-model="localMesage.bidType" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目全称:" prop="projectName">
                    <el-input :disabled='true' placeholder="请输入项目全称" v-model="localMesage.projectName" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="项目编号:" prop="projectCode">
                    <el-input :disabled='true' placeholder="请输入项目编号" v-model="localMesage.projectCode" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同金额(元):" prop="contractAmount">
                    <el-input :disabled='true' placeholder="请输入合同金额" v-model="localMesage.contractAmount" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="合同工期(天):" prop="contractDuration">
                    <el-input :disabled='true' placeholder="请输入合同工期" v-model="localMesage.contractDuration" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="企业资质:" prop="qualifications">
                    <el-input placeholder="请输入企业资质" v-model="localMesage.qualifications" style="width: 260px;height:40px;" />
                  </el-form-item>
                  <el-form-item label="开工时间:" prop="projectStartTime">
                    <el-date-picker v-model="localMesage.projectStartTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择开工时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="完工时间:" prop="projectEndTime">
                    <el-date-picker v-model="localMesage.projectEndTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择完工时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="工期(天):" prop="duration">
                    <el-input placeholder='请输入工期' v-model="localMesage.duration" style="width: 260px;height:40px;"></el-input>
                  </el-form-item>
                  <el-form-item label="核定担保金额:" prop="guaranteeAmount">
                    <el-input :disabled='true' placeholder='请输入核定担保金额' v-model="localMesage.guaranteeAmount" style="width: 260px;height:40px;">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="核定生效日期:" prop="verifyTime">
                    <el-date-picker :disabled='true' v-model="localMesage.verifyTime" type="date" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择核定生效日期" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="起保时间:" prop="startTime">
                    <el-date-picker v-model="localMesage.startTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择起保时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <el-form-item label="终保时间:" prop="endTime">
                    <el-date-picker v-model="localMesage.endTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择终保时间" size='large' style="width: 260px;" />
                  </el-form-item>
                  <div style="margin-top:10px;">
                    <div class="img-tip">
                      <div class="number-img"><img src="./../static/02-img.png" alt=""></div>
                      <div class="number-tip">附件</div>
                    </div>
                    <div style="margin-top:35px;display:flex;justify-content: space-between;flex-wrap: wrap;">
                      <div class="annex">
                        <el-form-item label="保函附件：" prop="guarantee_letter">
                          <el-upload v-model:file-list="localMesage.financial_statementsClone" class="upload-demo" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :headers="headers" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile">
                            <el-button type="primary" :icon="Upload">上传附件</el-button>
                          </el-upload>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;padding:0;">
              <el-button v-loading.fullscreen.lock="fullscreenLoading" type="primary" @click="submitForm(ruleFormRef)" style="width: 150px;height: 40px;font-size: 14px;">提交申请</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import { Upload } from '@element-plus/icons-vue'
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import { searchProject, applyInfoSubmit } from "@/api/index.js"
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
let fullscreenLoading = ref(false)
let localMesage = ref({
  endTime: "",
  institutionId: '',
  projectId: '',
  effectiveDate: '',
  guarantee_letter: [],
  financial_statementsClone: [],
})
let headers = { "blade-auth": 'bearer ' + localStorage.getItem('tokenuser') }
let titleArr = ref([
  { id: 1, title: "项目申请", url: require('./../static/ico-01.png') },
  { id: 2, title: "项目审核", url: require('./../static/step2.png') },
  { id: 3, title: "保证申请", url: require('./../static/step3.png') },
  { id: 4, title: "机构审核", url: require('./../static/ico-02.png') },
  { id: 5, title: "费用缴纳", url: require('./../static/ico-04.png') },
  { id: 6, title: "出函", url: require('./../static/ico-05.png') },
  { id: 6, title: "人社审核", url: require('./../static/ico-02.png') },
])
let router = useRouter()
let formSize = ref('default')
let ruleFormRef = ref()
let successFile = (response, uploadFile, uploadFiles) => {
  if (!localMesage.value.guarantee_letter) {
    localMesage.value.guarantee_letter = [];
  }
  if (response.code == 200) {
    localMesage.value.guarantee_letter.push({ attId: response.data.attachId, attType: 'guarantee_letter' })
    ruleFormRef.value.clearValidate('guarantee_letter');
  }
}
let validateTelTime = (rule, value, callback) => {
  if (value < localMesage.value.projectStartTime) {
    callback(new Error('完工时间不能小于开工时间'));
  } else {
    callback();
  }
}
let rules = ref({
  guarantee_letter: [{ required: true, message: '请上传保函附件', trigger: 'change' }],
  guaranteeSn: [{ required: true, message: '请输入保函编号', trigger: 'blur' }],
  institutionName: [{ required: true, message: '请输入承保机构', trigger: 'blur' }],
  institutionContact: [{ required: true, message: '请输入承保机构联系方式', trigger: 'blur' }],
  premiumAmount: [
    { required: true, message: '请输入保费金额', trigger: 'blur' },
    // { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的保费金额', trigger: 'blur' },
  ],
  premiumRate: [
    { required: true, message: '', trigger: 'blur' },
    // { pattern: /^\d+(\.\d+)?$/, message: '', trigger: 'blur' },
  ],
  endTime: [
    { required: true, message: '请输入终保时间', trigger: 'change' },
  ],
  depositDays: [
    { required: true, message: '', trigger: 'blur' },
    // { required: true, validator: TimeLength, trigger: 'blur' },
    // { pattern: /^[1-9]\d*$/, message: '', trigger: 'blur' },
  ],
  startTime: [
    { required: true, message: '请选择起保时间', trigger: 'change' },
  ],
  verifyTime: [
    { required: true, message: '请选择核定生效日期', trigger: 'change' },
  ],
  duration: [
    { required: true, message: '请输入工期', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的工期', trigger: 'blur' },
  ],
  qualifications: [
    { required: true, message: '请输入企业资质', trigger: 'blur' }
  ],
  bidType: [
    { required: true, message: '请输入投保人类型', trigger: 'blur' }
  ],
  projectName: [
    { required: true, message: '请输入项目全称', trigger: 'blur' }
  ],
  projectStartTime: [
    { required: true, message: '请选择开工时间', trigger: 'blur' },
  ],
  projectEndTime: [
    { required: true, message: '请选择完工时间', trigger: 'blur' },
    { required: true, validator: validateTelTime, trigger: 'blur' }
  ],
  contractDuration: [
    { required: true, message: '请输入合同工期', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的合同工期', trigger: 'blur' },
  ],
  contractAmount: [
    { required: true, message: '请输入合同金额', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的合同金额', trigger: 'blur' },
  ],
  guaranteeAmount: [
    { required: true, message: '请输入核定担保金额', trigger: 'blur' },
    { pattern: /^\d+(\.\d+)?$/, message: '请输入正确的核定担保金额', trigger: 'blur' },
  ],
  projectCode: [
    { required: true, message: '请输入项目编号', trigger: 'blur' },
  ],
})
let submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (localMesage.value.guarantee_letter && localMesage.value.guarantee_letter != '') {
      ruleFormRef.value.clearValidate('guarantee_letter');
    }
    if (valid) {
      fullscreenLoading.value = true
      localMesage.value.projectId = router.currentRoute.value.query.orderId
      localMesage.value.applyWay = router.currentRoute.value.query.applyWay
      localMesage.value.effectiveDate = localMesage.value.verifyTime
      localMesage.value.attList = [...localMesage.value.guarantee_letter]
      applyInfoSubmit(localMesage.value).then((res) => {
        if (res.code == 200) {
          router.push('/applicationInsuranceSuccess')
          fullscreenLoading.value = false
        }
      })
    } else {
      console.log('error submit!', fields);
    }
  });
  fullscreenLoading.value = false
}
let handleRemove = (file, uploadFiles) => {
  localMesage.value.financial_statementsClone.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      localMesage.value.financial_statementsClone.splice(index, 1)
    }
  })
  localMesage.value.guarantee_letter.forEach((item, index) => {
    if (file.response.data.attachId == item.attId) {
      localMesage.value.guarantee_letter.splice(index, 1)
    }
  })
}
let handlePreview = (uploadFile) => {
  ElMessageBox.confirm('确认下载此附件吗？', '附件下载',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      window.open(uploadFile.response.data.link)
    }).catch(() => {
      return
    })
}
let handleExceed = (files, uploadFiles) => {
  ElMessage.warning(
    `The limit is 3, you selected ${files.length} files this time, add up to ${files.length + uploadFiles.length
    } totally`
  )
}
let beforeRemove = (uploadFile, uploadFiles) => {
  return true
}
let GetProject = async () => {
  await searchProject({ id: router.currentRoute.value.query.orderId }).then(res => {
    delete res.data.id;
    localMesage.value = res.data
  })
}
onMounted(async () => {
  await GetProject()
})
</script>
<style scoped>
.annex ::v-deep .el-form-item {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.annex ::v-deep .el-form-item--default .el-form-item__label {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
::v-deep .upload-demo {
  width: 100%;
}
::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: 700;
  color: rgb(0, 82, 217);
}
::v-deep .el-checkbox.el-checkbox--small .el-checkbox__label {
  font-size: 16px;
  color: #000;
}
::v-deep .el-form-item__label-wrap {
  align-items: center;
}
::v-deep .el-form-item--default .el-form-item__label {
  height: 40px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
::v-deep
  .el-form--default.el-form--label-top
  .el-form-item
  .el-form-item__label {
  line-height: 22px;
  margin-bottom: 8px;
  width: 302px;
  display: flex;
  justify-content: flex-start;
}
.btn-false {
  border-radius: 6px;
  background: rgb(236, 245, 255);
  border: 1px solid rgb(59, 133, 244);
  box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3),
    0px 1px 3px 1px rgba(60, 64, 67, 0.15);
  font-size: 12px;
  color: rgba(59, 133, 244, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 3px 8px;
  margin-left: 10px;
}
.btn-false:nth-child(1) {
  margin-left: 0;
}
.tit {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
}
.jt {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  height: 20px;
}
.el-upload__tip {
  font-size: 14px;
  font-weight: 400;
  color: rgba(182, 186, 210, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.annex {
  width: 350px;
  border-radius: 6px;
  background: rgba(248, 249, 252, 1);
  border: 1px dashed rgba(194, 198, 206, 1);
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.img-tip {
  display: flex;
  align-items: center;
  position: relative;
}
.number-img {
  position: absolute;
  top: 0;
  left: 0;
}
.number-tip {
  height: 64px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 82, 217, 1);
  text-align: end;
  margin-left: 20px;
  line-height: 90px;
}
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.tp150 {
  margin-top: 150px;
}
.SignUpForms-box {
  padding-top: 135px;
  box-sizing: border-box;
}
.shadow {
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  padding: 30px 32px;
  box-sizing: border-box;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
.header-img {
  width: 100%;
  height: 340px;
  background: url("./../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.title-img {
  width: 360px;
  height: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -105px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px 2px rgba(0, 82, 217, 0.1);
  padding: 20px 0;
  box-sizing: border-box;
}
.SignUpForms {
  width: 100%;
  background: rgb(246, 247, 250);
}
</style>
